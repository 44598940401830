import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DEFAULT_ICON_SIZE, XIconProps } from './types';
import { mapIconColorToPalette } from 'shared/theme/utils';
import { greyIconsColor } from 'shared/theme';

export function XArrowDropDown({ color, size = DEFAULT_ICON_SIZE, style, onClick }: XIconProps) {
  let mappedColor = greyIconsColor;
  if (color != null) {
    mappedColor = mapIconColorToPalette[color] ?? color;
  }
  return (
    <ArrowDropDownIcon
      onClick={onClick}
      style={{
        color: mappedColor,
        height: size,
        width: size,
        ...style,
      }}
    />
  );
}
