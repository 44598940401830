import { Cursor, IconColor, XStyle } from 'shared/theme/types';

export type XIconProps = {
  color?: IconColor | string;
  cursor?: Cursor;
  size?: number;
  style?: XStyle;
  onClick?: () => void;
};

export type XIconName =
  | 'arrow-back'
  | 'arrow-down'
  | 'arrow-drop-down'
  | 'arrow-up'
  | 'bank'
  | 'business'
  | 'business-center'
  | 'car'
  | 'category'
  | 'chart-pie'
  | 'chevron-down'
  | 'chevron-right'
  | 'circle'
  | 'close'
  | 'credit-card'
  | 'email'
  | 'eye'
  | 'eye-off'
  | 'filter-variant'
  | 'home'
  | 'information-outline'
  | 'launch'
  | 'lock'
  | 'plus'
  | 'policy'
  | 'phone'
  | 'request-quote'
  | 'school'
  | 'store'
  | 'style'
  | 'trending-up'
  | 'tune';

export const DEFAULT_ICON_SIZE = 24;
